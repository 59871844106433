.site-footer {
}

.site-footer__items {
}

.site-footer__item a {
  @include link-color($grey);
}

.site-footer__item p {
  text-indent: 0;
}

.site-footer__item + .site-footer__item {
  margin-top: 0.5rem;
}


