.post {

}

.post__header {
  margin-bottom: 1rem;
}

.post__title {
  color: $brand-color;
  font-size: 3.2rem;
  letter-spacing: -1px;
  line-height: 1;
  margin-bottom: 0.125rem;
}

.post__meta {
  font-family: $sans-serif;
  color: $blackish;

  @include clearfix;

  a {
    color: inherit;
  }
}

.post__categories {
  float: left;
  padding-left: 2rem;
  background-image: url(/assets/img/tags.png);
  background-repeat: no-repeat;
  background-position: 0px 4px;
}

.post__date {
  float: right;
}

.post__content {
  // now we have a lot of markdown in here, cannot BEM that.

  h1, h2, h3, h4, h5, h6 {
    color: $brand-color;
    margin: 1em 0;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2.0rem;
  }

  h4 {
    font-size: 1.75rem;
  }

  p {
    margin: 1.6rem 0 0;
    @include clearfix();

    > img {
      max-width: 61%;
    }
  }
}

.post__footer {
  text-align: right;
}
