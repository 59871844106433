.home {

}

.home__posts {

}

.home__post + .home__post {
  margin-top: 5rem;
}

.home__pagination {
  @include clearfix;
  margin-top: 2rem;
}

.home__pagination-link {

  &.m--next {
    float: left;
  }

  &.m--prev {
    float: right;
  }
}
