.post-summary {
  @include clearfix();

  & + .post-summary {
    margin-top: 1rem;
  }
}

.post-summary__date {
  display: inline-block;

  min-width: 7.5rem;
}

.post-summary__title {
  display: inline-block;
  
  color: $brand-color;
  font-size: 1.75rem;
  margin: 0;
}

.post-summary__categories{
  float: right;

  font-family: $sans-serif;
  color: $blackish;

  &:before {
    content: " ";
    padding-left: 20px;
    background-image: url(/assets/img/tags.png);
    background-repeat: no-repeat;
    background-position: 0px 1px;
  }

  a {
    color: inherit;
  }
}

