.site-header {
}

.site-header__title {
  font-family: $sans-serif;
  font-size: 3.25rem;

  @include link-color($text-color);

  &:visited {
    color: $text-color;
  }
}

