// default

.site-header,
.site-navigation,
.site-content,
.site-footer {
  box-sizing: border-box;
}

.site-content {
  @include transition(width, 0.5s);
}


@media only screen and (max-width : $on-laptop) {
  .site-header,
  .site-navigation,
  .site-footer {
    width: 100%;
    clear: both;
    padding: 1rem 1rem 0 1rem;
  }

  .site-content {
    width: 100%;
    clear: both;
    margin: 0;
  }

  .site-navigation__item,
  .site-footer__item {
    display: inline-block;
    margin: 0 1rem 1rem 0;
  }

}

@media only screen and (min-width:  $on-laptop) {
  .site-header {
    position: fixed;
    top: 2rem;
    left: 2rem;
  }

  .site-navigation {
    position: fixed;
    top: 8rem;
    left: 2rem;
  }

  .site-content {
    width: 75%;
    float: right;
  }

  .site-footer {
    position: absolute;
    left: 2rem;
    bottom: 2rem;

    max-width: 15rem;
  }
}

@media only screen and (min-width:  $on-desktop) {
  .site-content {
    width: 62%;
    float: none;
    margin: 0 auto;
  }
}

@media only screen and (min-width: $on-desktop) {
  .site-content {
    max-width: 1495px;
  }
}

