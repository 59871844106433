.image {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;

  &:not(.m--naked) {
    border-radius: 0.33rem;
    box-shadow: rgba(0,0,0, 0.15) 0 1px 4px;
    border: $blackish 0.75rem solid;
  }

  &.m--full {
    max-width: 100%;
  }

  &.m--clothed {
    background: white;
  }

  &.m--left {
    float: left;
    margin-right: 1.5rem;
    margin-bottom: 0.33rem;
  }

  &.m--right {
    float: right;
    margin-left: 1.5rem;
    margin-bottom: 0.33rem;
  }

  &.m--center {
    display: block;
    margin: 0 auto 0.33rem;
    float: none;
  }
}

