.site-content {
  background: $background-color;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width : $on-laptop) {
  .site-content {
    border-top: 1px solid darken($background-color, 15%);
    border-right: 0;
    border-bottom: 1px solid darken($background-color, 15%);
    border-left: 0;
  }
}

@media only screen and (min-width:  $on-laptop) {
  .site-content {
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid darken($background-color, 15%);
    border-bottom-left-radius: 0.75rem;
    border-left: 1px solid darken($background-color, 15%);
  }
}

@media only screen and (min-width:  $on-desktop) {
  .site-content {
    border-top: 0;
    border-right: 1px solid darken($background-color, 15%);
    border-bottom-right-radius: 0.75rem;
    border-bottom: 1px solid darken($background-color, 15%);
    border-bottom-left-radius: 0.75rem;
    border-left: 1px solid darken($background-color, 15%);
  }
}

@media only screen and (min-width: $on-desktop) {
  .site-content {
  }
}
