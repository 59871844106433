.page {

}

.page__header {
  margin-bottom: 1rem;
}

.page__title {
  color: $brand-color;
  font-size: 3.2rem;
  letter-spacing: -1px;
  line-height: 1;
  margin-bottom: 0.125rem;
}

.page__content {

}
