.archive {

}

.archive__year {
  margin-top: 4rem;
  margin-bottom: 1rem;

  &:first-of-type {
    margin-top: 0;
  }
}
