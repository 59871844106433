.site-navigation {
}

.site-navigation__items {
}

.site-navigation__item {
  font-size: 1.5rem;
  font-family: $sans-serif;
  margin-bottom: 0.75rem;
}

.site-navigation__link {
  text-transform: lowercase;
  @include link-color($grey-dark);
}

