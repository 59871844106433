@charset "utf-8";
@import "normalize";

// Define defaults for each variable.

     $serif: "PT Serif", Georgia, Times, "Times New Roman", serif;
$sans-serif: "PT Sans", "Helvetica Neue", Arial, sans-serif;

$font-family: $serif;
$font-size:   10px;

$text-color:       #ffffff;
$background-color: rgba(0, 0, 0, 0.44);
$brand-color:      #00ff78;

$grey:       #828282;
$grey-light: lighten($grey, 40%);
$grey-dark:  darken($grey, 25%);
$blackish:   #222222;

// Width of the content area
$content-width: 960px;

$on-handphone:  480px; // 768px; // or 480px;
$on-laptop:     768px; // 1024px;
$on-desktop:    1024px; // 1224px;

@mixin transition($property, $duration) {
  -webkit-transition-property: $property;
  transition-property: $property;

  -webkit-transition-duration: $duration;
  transition-duration: $duration;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin link-color($color) {
  color: $color;
  &:visited {
    @include transition(color, 1s);
    color: darken($color, 10%);
  }
  &:hover {
    @include transition(color, 1s);
    color: lighten($color, 10%);
  }
}

* {
  margin: 0;
  padding: 0;
  // border: none;
}

html {
  height: 100%;
  font-size: $font-size;
  box-sizing: border-box;
}
@media screen and (max-width: $on-laptop){
  html { font-size: $font-size - 1px; }
}
@media screen and (max-width: $on-handphone){
  html { font-size: $font-size - 2px; }
}

body {
  font-family: $font-family;
  font-size: 1.6rem;
  line-height: 2.0rem;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $text-color;

  background-color: $background-color;
  background-image: url(/assets/img/bem/linen_background_grey.png);

  position: relative;
  box-sizing: border-box;
  min-height: 100%;

  @include clearfix;
}

a {
  text-decoration: none;
  @include link-color($brand-color);
  &:hover {
    text-decoration: underline;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.675em;
}

blockquote {
  font-style: italic;
  font-size: 2.25rem;
  line-height: 2.5rem;
  border-left: 4px solid rgba(255, 255, 255, 0.5);
  padding-left: 1rem;
  margin: 1rem 0 1rem 40px;

  cite {
    color: #AAAAAA;
    word-wrap: break-word;

    &:before {
      content: "\2014";
      padding-right: 0.33rem;
      padding-left: 0.33rem;
    }
  }
}

p {
  // text-indent: 1em;
}

pre {
  overflow: auto;
}

ol, ul {
  list-style: none;
}


// Import partials.
@import
  "bem/responsive-layout",

  "bem/site-header",
  "bem/site-navigation",
  "bem/site-content",
  "bem/site-footer",

  "bem/home",
  "bem/archive",
  "bem/page",
  "bem/post",
  "bem/post-summary",

  "bem/image",
  "bem/icon"
;
